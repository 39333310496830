<template>
  <div class="t_menu_set">
    <div class="stu-module-header">
      <div class="stu-module-title">菜单设置</div>
      <div>
        <el-button @click="save" type="primary">保存</el-button>
        <el-button @click="back">返回</el-button>
      </div>
    </div>
    <div class="content">
      <el-checkbox-group v-model="checkList" @change="change">
        <el-checkbox
          v-for="(item, index) in menu"
          :label="item.code"
          :key="index"
        >
          {{ item.title }}
        </el-checkbox>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "t_menu_set",
  data() {
    return {
      menu: [
        {
          code :1,
          svg: "",
          title: "课程资源",
          path: "/teacher/t/course/resources",
          name: "t-course-resources",
        },
        {
          code :2,
          svg: "",
          title: "课程简介",
          path: "/teacher/t/course/introduction",
          name: "t-course-introduction",
        },
        {
          code :3,
          svg: "",
          title: "学习导航",
          path: "/teacher/t/study/nav",
          name: "t-study-nav",
        },
        {
          code :4,
          svg: "",
          title: "课程公告",
          path: "/teacher/t/course/notice",
          name: "t-course-notice",
        },
        {
          code :5,
          svg: "",
          title: "课程管理",
          path: "/teacher/t/course/manage",
          name: "t-course-manage",
        },
        {
          code :6,
          svg: "",
          title: "在线答疑",
          path: "/teacher/t/question",
          name: "t-question",
        },
        {
          code :7,
          svg: "",
          title: "题库管理",
          path: "/teacher/t/answer/manage",
          name: "t-answer-manage",
        },
        {
          code :8,
          svg: "",
          title: "试卷管理",
          path: "/teacher/t/exampaper/manage",
          name: "t-exampaper-manage",
        },
        {
          code :9,
          svg: "",
          title: "在线作业",
          path: "/teacher/t/work/online",
          name: "t-work-online",
        },
        {
          code :10,
          svg: "",
          title: "成绩管理",
          path: "/teacher/t/result/manage",
          name: "t-result-manage",
        },
        {
          code :11,
          svg: "",
          title: "在线考试",
          path: "/teacher/t/exam/online",
          name: "t-exam-online",
        }

      ],
      checkList: [],
    };
  },
  components:{
    course(){
      return this.$store.getters.getTCourse;
    }
  },
  methods: {
    save() {
      let keyName = this.routsKeyName + this.$store.getters.getTCourse.id;
      let pushList = [];
      this.checkList.forEach(c=>{
        pushList.push(this.menu.find(m=> m.code === c));
      });
      pushList.push({
        code :12,
        svg: "",
        title: "菜单设置",
        path: "/teacher/t/menu/set",
        name: "t-menu-set",
      })
      localStorage.setItem(keyName,JSON.stringify(pushList));
      this.$message.success("修改成功");
      setTimeout(()=>{
        window.location.reload();
      },1000);
    },
    back() {},
    change(val) {
    },
  },
  activated(){
    this.checkList = [];
    this.openLoadingView();
    let keyName = this.routsKeyName + this.$store.getters.getTCourse.id;
    // localStorage.setItem(keyName,null);
    let list = JSON.parse(localStorage.getItem(keyName));
    if (list == null){
      this.menu.forEach(m=>{
        this.checkList.push(m.code);
      })
    }else{
      list.forEach(l=>{
        if (l.code == 12){
          return;
        }
        this.checkList.push(l.code);
      })
    }
    this.loadingView.close();
  },
  created() {

  }
};
</script>

<style lang="less" scoped>
.t_menu_set {
  box-shadow: @shadow;
  background: #fff;
  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
  }
  .content {
    padding: 20px 20px 0;
    .el-checkbox {
      margin-right: 100px;
      margin-bottom: 20px;
    }
  }
}
</style>